:root {
  --bg-color: #e4e4e4;
  /* --bg-color: #ed6312; */
  --link-color: white;
  --text-color: black;
  --text-color-white: white;
  --button-color: rgba(0, 0, 255, 0.451);
  /* --overlay-color: rgba(255, 255, 255, 0.327); */
  --overlay-color: rgba(0, 0, 255, 0.271);
  --overlay-color-stripe: rgba(248, 248, 248, 0.891);
}

body {
  background-color: var(--bg-color);
}

a {
  color: var(--link-color);
}

h1,
h2,
p,
input,
label,
div {
  color: var(--text-color);
  text-align: center;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  min-height: 100vh;
  justify-content: center;
}

.containerEstimat,
.summaryEstimat,
.emailQuestion {
  display: flex;
  width: 50vw;
  min-height: 30vw;
  background-color: var(--overlay-color);
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

}

#stripeCheckout {
  background-color: var(--overlay-color-stripe);
  width: 1000px;
}

.p-FieldLabel {
  color: var(--link-color);
}

.p-FieldError {
  color: #00ffc0;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;

}

.next,
.finish,
.getOffer {
  padding: 15px 50px;
  /* margin: 1vw 18vw; */
  /* border-radius: 6px; */
  border-radius: 18px 0px 18px 0px;
  background-color: var(--button-color);
  border: none;
  position: relative;
  /* top: 60px; */
  /* right: -100px; */
  color: grey;
  color: var(--text-color-white)
}



#paymentButton {
  padding: 10px 20px;
  background-color: var(--button-color);
  border-radius: 6px;
  margin: 1vw 0vw;
  border: none;
  color: var(--text-color-white)
}

.getOffer {
  /* top: 10px; */
  /* right: -245px; */
}

.next:hover,
.finish:hover {
  background-color: var(--bg-color);
  color: var(--text-color);
  border: none;
}

.formFlex {
  display: flex;
  flex-direction: row;
  /* flex-direction: column; */
  align-items: start;
  gap: 10px;
  max-width: 400px;
  flex-wrap: wrap;
  justify-content: center;
  /* flex-wrap: wrap;
  justify-content: space-between;
  max-width: 300px; */
}

.finish {
  display: none;
}

#summary {
  font-size: 66px;
  color: var(--text-color);
}

.summaryEstimat,
.emailQuestion {
  display: none;
}

input[type="number"],
input[type="email"] {
  color: var(--text-color);
  background-color: rgba(255, 255, 255, 0);
  border: 0px solid black;
  border-bottom: 4px solid var(--button-color);
  border-radius: 5px;
  margin-bottom: 12px;
  /* width: 150px; */
  /* height: 100px; */
}

input[type="number"]:focus, input[type="email"]:focus {
  border: 0px solid black;
  outline: none;
  border-bottom: 4px solid var(--text-color-white);
  background-color: rgba(255, 255, 255, 0);

}

li {
  text-align: start;
}

input[type="radio"], input[type="checkbox"] {
  position: absolute;
  opacity: 0; 
  width: 150px;
  height: 100px;
}

input[type="checkbox"] {
  filter: invert(2%) hue-rotate(8deg) brightness(1.5);
  /* filter: invert(9%) sepia(65%) saturate(4895%) hue-rotate(252deg) brightness(63%) contrast(122%); */
  /* filter: saturate(100%) invert(8%) sepia(48%) saturate(6286%) hue-rotate(249deg) brightness(73%) contrast(118%); */
}

.question {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 10px;
  width: 150px;
  height: 100px;
  flex-wrap: wrap;

}
/* input[type="checkbox"]:checked:after { */
/* width: 10px; */
/* height: 10px; */
/* border-radius: 15px; */
/* top: -0.7px; */
/* left: -0.7px; */
/* position: relative; */
/* background-color: #080045; */
/* content: ""; */
/* display: inline-block; */
/* visibility: visible; */
/* border: 2px solid var(--link-color); */
/* } */

.active {
  background-color: var(--button-color);
  
}

.active label {
  color: var(--text-color-white);
}

.contactOffer, .buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: space-between; */
}

.contactOffer a, .buttons a {
  padding-left: 20px;
  padding-right: 70px;
  font-weight: 900;
}



@media only screen and (max-width: 440px) {
  .containerEstimat, .summaryEstimat, .emailQuestion {
    width: 100%;
    margin-bottom: 100px;
  }

  .formFlex {
    display: flex;
    flex-direction: column;
    /* flex-direction: column; */
    align-items: start;
    gap: 10px;
    max-width: 300px;
    flex-wrap: wrap;
  }

  .formFlex .question {
    width: 200px;
  }

  input[type="radio"], input[type="checkbox"] {
    width: 200px;
    height: 100px;
  }
}